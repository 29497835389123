import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import UploadPhotoModal from "../../editor/UploadPhotoModal/UploadPhotoModal";
import {
  createPhotosDb,
  formatErrorSlack,
  getS3File,
} from "../../editor/utils";
import { sendSlackError } from "../DashboardPage/utils";
import WidgetContainer from "../WidgetContainer/WidgetContainer";
import { toast } from "react-toastify";
import { photosToast } from "../../../../hooks/utils";
import UploadPhotoIcon from "../../editor/UploadPhotoModal/UploadPhotoIcon";
import GalleryUpload from "../../editor/GalleryUpload/GalleryUpload";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";

interface PhotoUploadWidgetProps {
  showGalleryModal: boolean;
  setShowGalleryModal: (arg0: boolean) => void;
  images: any[];
  setImages: (arg0: any[]) => void;
  files: any[];
  setFiles: (arg0: any[]) => void;
  isProcessingImages: boolean;
  setIsProcessingImages: (arg0: boolean) => void;
}

export default function PhotoUploadWidget({
  showGalleryModal,
  setShowGalleryModal,
  images,
  setImages,
  files,
  setFiles,
  isProcessingImages,
  setIsProcessingImages,
}: PhotoUploadWidgetProps) {
  const isMobile = useIsMobile();
  const { profileInfo, userGalleries, mutatePhotos, mutateGalleries } =
    useContext(DesktopContext);
  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();

  const { slug } = profileInfo || {};

  const [galleries, setGalleries] = useState<any[]>([]);
  const [gallerySelected, setGallerySelected] = useState<string>("All Photos");
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [uploadedCount, setUploadedCount] = useState<number>(0);

  useEffect(() => {
    getGalleries();
  }, [userGalleries]);

  async function getGalleries() {
    if (!userGalleries) return;
    const allPhotosGallery = {
      "gallery-name": "All Photos",
      "gallery-subtitle": "",
    } as any;
    if (galleries && galleries.length === 0) {
      const galleryResponse = userGalleries;
      if (galleryResponse.status === "success") {
        setGalleries([allPhotosGallery, ...galleryResponse.message.galleries]);
      }
    }
  }

  async function addPhotos(
    images: any[],
    gallery: string,
    uploadTo: {
      facebook: boolean;
      google: boolean;
    }
  ) {
    try {
      setIsUploading(true);

      for (let i = 0; i < images.length; i++) {
        const signedRequest = await getS3File(files[i]);
        images[i].signedRequest = signedRequest;
        delete images[i].data;
        setUploadedCount(1 + i);
      }

      await createPhotosDb(images, gallery, uploadTo);
      photosToast(images);

      setIsUploading(false);
      setShowGalleryModal(false);

      mutatePhotos();
      mutateGalleries();

      setUploadedCount(0);
    } catch (error) {
      handleUploadError(uploadTo, error);
    }
  }

  function handleUploadError(uploadTo, error) {
    const toggleFacebookStatus = uploadTo?.facebook || false;
    const toggleGoogleStatus = uploadTo?.google || false;
    const formattedError = formatErrorSlack(error);

    sendSlackError(
      slug,
      formattedError,
      "EditorPage.tsx",
      `uploading to: Facebook --> ${toggleFacebookStatus}, Google --> ${toggleGoogleStatus}`,
      "Photo Upload Error"
    );
    console.error("error publishing", error.message);
    setIsUploading(false);
    toast.error("Unable to add photo. Please try again.");
  }

  function handleImages(uploadedImages: any[]) {
    const newImages = [...uploadedImages];
    setImages(newImages);
    setShowGalleryModal(true);
  }

  return (
    <>
      <WidgetContainer>
        <div className="flex justify-between items-center">
          <h1 className="text-h2-bold text-gray-900">Add a Photo</h1>
        </div>
        {renderChurnModal()}
        <GalleryUpload
          setIsProcessingImages={setIsProcessingImages}
          processImages={handleImages}
          setFiles={setFiles}
        >
          <div
            onClick={() => {
              if (shouldOpenChurnModal()) return;
            }}
            className="flex flex-col space-y-5 bg-white rounded justify-between py-4 w-full"
          >
            <div className="flex h-full bg-white items-center justify-center border-2 border-gray-200 border-dashed cursor-pointer py-4">
              <div className="space-y-1 text-center">
                <div className="flex flex-col items-center space-y-1 text-gray-600 text-body-normal">
                  <UploadPhotoIcon />
                  <label
                    htmlFor="file-upload"
                    className="relative pointer-events-none cursor-pointer sm:bg-white rounded-md text-body-normal focus-within:outline-none focus:outline-none"
                  >
                    <span className="bg-white inline text-sm-medium text-blue-600">
                      Upload{" "}
                    </span>
                    <span className="bg-white inline text-sm-normal">
                      a photo or video
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </GalleryUpload>
        <div className="flex justify-center">
          <Link href="/site">
            <a>
              <PrimaryLink
                size={isMobile ? "sm" : "md"}
                text="View all photos"
                icon="right-arrow"
                iconPlacement="lagging"
                customStyle="font-medium"
              />
            </a>
          </Link>
        </div>
      </WidgetContainer>

      <UploadPhotoModal
        isOpen={showGalleryModal}
        setIsOpen={setShowGalleryModal}
        images={images}
        setImages={setImages}
        gallerySelected={gallerySelected}
        setGallerySelected={setGallerySelected}
        onSubmitHandler={() => {}}
        galleries={galleries}
        setGalleries={setGalleries}
        addPhotos={addPhotos}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        setFiles={setFiles}
        uploadedCount={uploadedCount}
      />
    </>
  );
}
