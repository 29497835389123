import React, { useRef, useEffect } from "react";
import { XIcon } from "@heroicons/react/solid";
import PrimaryButton from "../../uiwrappers/PrimaryButton/PrimaryButton";
import { addToHomeScreen } from "../../../pages/_app";

interface AppDownloadPromptProps {
  userAgent: string;
  showDownloadPopup: boolean;
  hideDownloadPopup: () => void;
}

export const AppDownloadPrompt: React.FC<AppDownloadPromptProps> = ({
  userAgent,
  showDownloadPopup,
  hideDownloadPopup,
}) => {
  if (!showDownloadPopup) {
    return null;
  }
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(e: any) {
      if (ref?.current && !ref?.current?.contains(e?.target)) {
        hideDownloadPopup();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideDownloadPopup]);

  if (!showDownloadPopup) {
    return null;
  }

  const getInstructionBox = (arrowDirection: string, positionLabel: string) => {
    return (
      <div
        ref={ref}
        className="relative bg-blue-600 shadow-2xl rounded-lg px-6 py-4 m-4 text-sm text-white text-center"
      >
        <div className="text-lg font-normal mb-6 flex justify-center items-center my-6">
          Click the
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "4px", marginRight: "4px" }}
          >
            <path
              d="M14.0052 16.6906C14.3464 16.6906 14.6358 16.3993 14.6358 16.0432V4.42086L14.5945 2.85612L15.5146 3.84892L17.2723 5.75899C17.386 5.88849 17.5514 5.95324 17.7065 5.95324C18.027 5.95324 18.2855 5.70504 18.2855 5.3705C18.2855 5.18705 18.2131 5.05755 18.0994 4.92806L14.4601 1.21583C14.305 1.05396 14.1706 1 14.0052 1C13.8397 1 13.7053 1.05396 13.5503 1.21583L9.91097 4.92806C9.7869 5.05755 9.71453 5.18705 9.71453 5.3705C9.71453 5.70504 9.96267 5.95324 10.2935 5.95324C10.4486 5.95324 10.6244 5.88849 10.7381 5.75899L12.4957 3.84892L13.4159 2.85612L13.3745 4.42086V16.0432C13.3745 16.3993 13.664 16.6906 14.0052 16.6906ZM8.04997 25H19.95C21.9661 25 23 23.9209 23 21.8489V10.8201C23 8.73741 21.9661 7.65827 19.95 7.65827H17.0034V8.9964H19.919C21.0666 8.9964 21.718 9.64389 21.718 10.8849V21.7734C21.718 23.0252 21.0666 23.6619 19.919 23.6619H8.07065C6.91269 23.6619 6.28202 23.0252 6.28202 21.7734V10.8849C6.28202 9.64389 6.91269 8.9964 8.07065 8.9964H11.0069V7.65827H8.04997C6.03389 7.65827 5 8.72662 5 10.8201V21.8489C5 23.9317 6.03389 25 8.04997 25Z"
              fill="white"
            />
          </svg>
          icon {positionLabel}
        </div>

        <div className="text-lg font-normal mb-3">Scroll down and click:</div>
        <div className="bg-white text-gray-900 text-base font-normal rounded-lg py-2 px-4 flex justify-between items-center">
          <span>Add to Home Screen</span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ border: "1px solid #0F172A", borderRadius: "25%" }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 2.5C8.27614 2.5 8.5 2.72386 8.5 3V7.5H13C13.2761 7.5 13.5 7.72386 13.5 8C13.5 8.27614 13.2761 8.5 13 8.5H8.5V13C8.5 13.2761 8.27614 13.5 8 13.5C7.72386 13.5 7.5 13.2761 7.5 13V8.5H3C2.72386 8.5 2.5 8.27614 2.5 8C2.5 7.72386 2.72386 7.5 3 7.5H7.5V3C7.5 2.72386 7.72386 2.5 8 2.5Z"
              fill="#0F172A"
            />
          </svg>
        </div>

        <div
          style={{
            ...(arrowDirection === "triangle-down"
              ? { bottom: -8 }
              : { top: -8 }),
            left: arrowDirection === "triangle-down" ? "50%" : "90%",
            transform: "translateX(-50%)",
          }}
          className={`absolute ${arrowDirection}`}
        ></div>
      </div>
    );
  };

  switch (userAgent) {
    case "Android":
      return (
        <div className="sm:hidden flex justify-center mb-48">
          <PrimaryButton
            onClickFunc={addToHomeScreen}
            size="md"
            orange
            text="Download App"
            icon="export"
            iconPlacement="lagging"
          />
        </div>
      );

    case "Apple - Safari":
      return (
        <div className="sm:hidden fixed left-0 right-0 bottom-5 z-50">
          <XIcon
            onClick={hideDownloadPopup}
            className="w-5 h-5 text-white absolute right-4 top-4 cursor-pointer"
          />
          {getInstructionBox("triangle-down", "below")}
        </div>
      );

    case "Apple - Chrome":
      return (
        <div className="sm:hidden fixed left-0 right-0 top-5 z-50">
          {getInstructionBox("triangle-up", "in the top right")}
        </div>
      );

    default:
      return null;
  }
};
